/* Main Styling */
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --link-color: #319000;
  --heading-color: #fff;
}
html,
body {
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  height: 100%;
  color: #323232;
}
form {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
  text-transform: uppercase;
}
h1,
h3 {
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: -0.1rem;
}

h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: -0.05rem;
  margin: 0.7rem 0;
}
.main-btn {
  border: 2px solid var(--link-color);
  background-color: var(--link-color);
  font-size: 0.95rem;
  line-height: 1.05rem;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  color: #ffff;
}

.main-btn:hover {
  border: 1px solid var(--link-color);
  background-color: #fff;
  transition: 0.5s;
  color: var(--link-color);
  cursor: pointer;
}

main section,
footer section {
  padding: 8rem 0;
}

footer {
  background: linear-gradient(#4a290d, #331a06);
  color: #fff;
  line-height: 1.3rem;
}

/* ------- Header ------- */
header {
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.308), rgba(0, 0, 0, 0.37)),
    url("/img/header-background.jpg");
  min-height: 90vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-call-us {
  padding: 1rem;
  background-color: #319000;
  color: #ffff;
  font-size: 1.3rem;
}

.call-us {
  display: flex;
  justify-content: flex-end;
  font-size: 1.1rem;
}

.call-us a {
  display: flex;
  font-weight: 700;
  gap: 0.6rem;
}
/* Navigation Styling */
nav {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
}
.services-links li {
  padding: 0.5rem;
}

.services-links {
  opacity: 0;
  transition: opacity 0.5s ease;
  text-transform: capitalize;
  font-weight: 300;
  list-style-type: none;
  position: absolute;
  margin: 0;
  padding: 0.5rem;
  background-color: #fff;
  border: solid 2px #f2f2f2;
}
.services-dropdown {
  opacity: 1;
}

.services-links li:hover {
  background-color: #f2f2f2;
}
.burger-menu {
  display: none;
}
.nav-links {
  list-style-type: none;
  display: flex;
  margin-right: 1rem;
}

.nav-links li {
  border-bottom: 1px solid transparent;
}

.nav-links > *:hover {
  cursor: pointer;
  border-bottom: 1px solid rgba(199, 198, 198, 0.664);
  transition: border-bottom 0.2s;
}

.nav-links > * {
  padding: 0.85rem;
}

.container-logo {
  width: 17%;
  margin: 0.8rem;
}
.logo {
  /* width: 60%; */
  max-height: 150px;
  width: auto;
}

/* Slogan Section */
.slogan-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* color: #fff; */
}
.slogan-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  text-align: center;
  padding: 8rem 1rem;
  color: #fff;
}
.slogan-container > * {
  margin: 1rem auto;
}
.slogan-heading {
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.slogan-container hr {
  width: 50%;
  max-width: 350px;
}
.slogan-description {
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2.25rem;
  text-align: center;
}

.slogan-link {
  border: 2px solid #fff;
  padding: 1rem;
}
.slogan-link:hover {
  color: var(--link-color);
  background-color: #ffff;
  transition: 0.5s;
}

/* ------  Main ------ */

/* Services */
.service-section {
  width: 100%;
  margin: 0 auto;
}

.service-section hr,
.slogan-container hr {
  width: 50%;
  margin: 0 auto;
}
.service-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
}
.service-section hr {
  width: 25%;
  max-width: 100px;
}
.services {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.services h3 {
  font-size: 1.75rem;
  line-height: 2rem;
}

.service-img img {
  width: 100%;
  background-position: center;
  background-size: cover;
}

/* ------  Portfolio Section ------ */

.portfolio-section {
  background: linear-gradient(rgba(0, 0, 0, 0.281), rgba(0, 0, 0, 0.377)),
    url("/img/background-portfolio.jpg");
  color: #fff;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portfolio-heading-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.portfolio-heading {
  font-size: 2.75rem;
  line-height: 3.25rem;
}

.portfolio-hr {
  margin: 0 auto;
  width: 20%;
}
.portfolio-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 3rem;
}
.porftolio-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
  margin: 0 auto;
  max-width: 1500px;
  padding: 2rem;
}

.portfolio-item {
  width: 100%;
  border: 2px solid #ffff;
}
.portfolio-item img {
  width: 100%;
  height: 100%;
}

/* -----Company Desc and Consultatiopn Form -----  */
.companyDescription-section {
  max-width: 1500px;
  margin: 0 auto;
}
.company-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 4rem;
  padding: 2rem;
}

.company-desc {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

.company-desc p {
  font-size: 18px;
  line-height: 28px;
}
.description-heading {
  font-size: 2rem;
}
.aboutme-container {
  width: 40%;
}
.aboutme-img-container {
  width: 100%;
  height: auto;
}

.aboutme-img {
  width: 100%;
  height: 100%;
}

/* Requestion Consultation Form */

.form-wrapper-desc {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-wrapper-desc input {
  border: 0;
}

.form-wrapper-desc textarea {
  border: 0;
}

.consultation-heading {
  font-size: 2rem;
}
.consultation-form {
  width: 60%;
  background-color: #f2f2f2;
  padding: 1.5rem;
}
.input__container {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input__container input {
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  width: 100%;
  transition: all 0.3s ease-out;
  border: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  font-weight: 300;
}

.form-btn {
  width: 100%;
  margin-top: 2rem;
}

/* ------Footer Section ------- */
footer {
  padding-bottom: 0;
}

footer section {
  padding: 4rem 2rem;
}
.mailing-wrapper {
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 auto;
}
.mailing-wrapper :first-child {
  border-left: 0;
}
.mailing-wrapper > * {
  border-left: 1px solid #7f6a59;
  padding: 2rem;
  width: 30%;
}

.mailing-container p {
  margin-top: 1rem;
}

.form-wrapper-mail {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-wrapper-mail form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mail-btn {
  width: 100%;
}

.popular-list {
  margin: 10px 0 0 25px;
}

.popular-list li {
  margin-top: 3px;
  margin-bottom: 3px;
  text-transform: lowercase;
}

.popular-list li a {
  text-transform: capitalize;
}
.footerContact-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-middleBorder {
  height: 0.5px;
  width: 80%;
  margin: 2rem auto;
}

.footer-middleBorder hr {
  margin: 0 auto;
  height: 1px;
  border: 0;
  background-color: #7f6a59;
}

.footer-trademark {
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

/* Media Queries */
@media screen and (max-width: 1700px) {
  html {
    font-size: 18px;
  }

  .slogan-description {
    font-size: 1.75rem;
  }
  .slogan-link {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  /* Header */
  .container-logo {
    width: 25%;
  }

  header {
    position: relative;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    flex-basis: 100%;
  }
  .burger-menu {
    display: initial;
    font-size: 2rem;
    cursor: pointer;
  }
  nav {
    justify-content: space-between;
    padding: 0.8rem;
    flex-wrap: wrap;
  }
  /* Main */
  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    padding: 2rem;
  }
  .consultation-form {
    height: 50%;
  }

  /* Footer */
  footer {
    font-size: 15px;
  }
}
@media screen and (max-width: 720px) {
  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}
@media screen and (max-width: 530px) {
  /* Header */
  .nav-links {
    display: none;
  }
  .container-logo {
    width: 45%;
  }
  .main-btn {
    white-space: normal;
  }

  .portfolio-btn {
    width: 60%;
  }
  /* Main  */
  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
  .slogan-heading {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
  .porftolio-grid-container {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  .slogan-container {
    padding: 4rem 1rem;
  }

  .company-wrapper {
    flex-direction: column;
  }

  .company-wrapper > * {
    width: 100%;
  }
  /* Footer */
  footer section {
    padding: 0;
  }

  .footer-middleBorder {
    width: 100%;
  }

  .footer-trademark {
    padding: 1.5rem;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    gap: 2rem;
  }
  .mailing-wrapper {
    flex-direction: column;
  }
  .mailing-wrapper :first-child {
    border-top: 0;
  }
  .mailing-wrapper > * {
    width: 100%;
    border-left: 0;
    border-top: 1px solid #7f6a59;
  }
}
