*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --link-color: #319000;
  --heading-color: #fff;
}

html, body {
  text-rendering: optimizelegibility;
  height: 100%;
  color: #323232;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

form {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
}

h1, h3 {
  letter-spacing: -.1rem;
  font-family: Roboto Condensed, sans-serif;
}

h4 {
  letter-spacing: -.05rem;
  margin: .7rem 0;
  font-family: Roboto Condensed, sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.main-btn {
  border: 2px solid var(--link-color);
  background-color: var(--link-color);
  text-align: center;
  color: #fff;
  margin: 0 auto;
  padding: 1rem;
  font-size: .95rem;
  line-height: 1.05rem;
}

.main-btn:hover {
  border: 1px solid var(--link-color);
  color: var(--link-color);
  cursor: pointer;
  background-color: #fff;
  transition: all .5s;
}

main section, footer section {
  padding: 8rem 0;
}

footer {
  color: #fff;
  background: linear-gradient(#4a290d, #331a06);
  line-height: 1.3rem;
}

header {
  min-height: 90vh;
  background-color: #0000;
  background-image: linear-gradient(#0000004f, #0000005e), url("header-background.47741652.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  display: flex;
}

.header-call-us {
  color: #fff;
  background-color: #319000;
  padding: 1rem;
  font-size: 1.3rem;
}

.call-us {
  justify-content: flex-end;
  font-size: 1.1rem;
  display: flex;
}

.call-us a {
  gap: .6rem;
  font-weight: 700;
  display: flex;
}

nav {
  text-transform: uppercase;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  font-weight: 400;
  display: flex;
}

.services-links li {
  padding: .5rem;
}

.services-links {
  opacity: 0;
  text-transform: capitalize;
  background-color: #fff;
  border: 2px solid #f2f2f2;
  margin: 0;
  padding: .5rem;
  font-weight: 300;
  list-style-type: none;
  transition: opacity .5s;
  position: absolute;
}

.services-dropdown {
  opacity: 1;
}

.services-links li:hover {
  background-color: #f2f2f2;
}

.burger-menu {
  display: none;
}

.nav-links {
  margin-right: 1rem;
  list-style-type: none;
  display: flex;
}

.nav-links li {
  border-bottom: 1px solid #0000;
}

.nav-links > :hover {
  cursor: pointer;
  border-bottom: 1px solid #c7c6c6a9;
  transition: border-bottom .2s;
}

.nav-links > * {
  padding: .85rem;
}

.container-logo {
  width: 17%;
  margin: .8rem;
}

.logo {
  max-height: 150px;
  width: auto;
}

.slogan-sec {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slogan-container {
  width: 80%;
  text-align: center;
  color: #fff;
  flex-direction: column;
  justify-content: space-around;
  padding: 8rem 1rem;
  display: flex;
}

.slogan-container > * {
  margin: 1rem auto;
}

.slogan-heading {
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.slogan-container hr {
  width: 50%;
  max-width: 350px;
}

.slogan-description {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2.25rem;
}

.slogan-link {
  border: 2px solid #fff;
  padding: 1rem;
}

.slogan-link:hover {
  color: var(--link-color);
  background-color: #fff;
  transition: all .5s;
}

.service-section {
  width: 100%;
  margin: 0 auto;
}

.service-section hr, .slogan-container hr {
  width: 50%;
  margin: 0 auto;
}

.service-container {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

.service-section hr {
  width: 25%;
  max-width: 100px;
}

.services {
  max-width: 33.3333%;
  text-align: center;
  flex-direction: column;
  flex-basis: 33.3333%;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.services h3 {
  font-size: 1.75rem;
  line-height: 2rem;
}

.service-img img {
  width: 100%;
  background-position: center;
  background-size: cover;
}

.portfolio-section {
  color: #fff;
  background-color: #0000;
  background-image: linear-gradient(#00000048, #00000060), url("background-portfolio.a79a0a12.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.portfolio-heading-container {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.portfolio-heading {
  font-size: 2.75rem;
  line-height: 3.25rem;
}

.portfolio-hr {
  width: 20%;
  margin: 0 auto;
}

.portfolio-wrapper {
  text-align: center;
  flex-direction: column;
  gap: 3rem;
  display: flex;
}

.porftolio-grid-container {
  max-width: 1500px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 0 auto;
  padding: 2rem;
  display: grid;
}

.portfolio-item {
  width: 100%;
  border: 2px solid #fff;
}

.portfolio-item img {
  width: 100%;
  height: 100%;
}

.companyDescription-section {
  max-width: 1500px;
  margin: 0 auto;
}

.company-wrapper {
  justify-content: space-around;
  gap: 4rem;
  padding: 2rem;
  display: flex;
}

.company-desc {
  width: 50%;
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.company-desc p {
  font-size: 18px;
  line-height: 28px;
}

.description-heading {
  font-size: 2rem;
}

.aboutme-container {
  width: 40%;
}

.aboutme-img-container {
  width: 100%;
  height: auto;
}

.aboutme-img {
  width: 100%;
  height: 100%;
}

.form-wrapper-desc {
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  display: flex;
}

.form-wrapper-desc input, .form-wrapper-desc textarea {
  border: 0;
}

.consultation-heading {
  font-size: 2rem;
}

.consultation-form {
  width: 60%;
  background-color: #f2f2f2;
  padding: 1.5rem;
}

.input__container {
  flex-direction: column;
  gap: .5rem;
  margin-top: .5rem;
  display: flex;
}

.input__container input {
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  padding: .5rem .75rem;
  font-size: .85rem;
  font-weight: 300;
  transition: all .3s ease-out;
}

.form-btn {
  width: 100%;
  margin-top: 2rem;
}

footer {
  padding-bottom: 0;
}

footer section {
  padding: 4rem 2rem;
}

.mailing-wrapper {
  max-width: 1500px;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 auto;
  display: flex;
}

.mailing-wrapper :first-child {
  border-left: 0;
}

.mailing-wrapper > * {
  width: 30%;
  border-left: 1px solid #7f6a59;
  padding: 2rem;
}

.mailing-container p {
  margin-top: 1rem;
}

.form-wrapper-mail {
  width: 100%;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
  display: flex;
}

.form-wrapper-mail form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.mail-btn {
  width: 100%;
}

.popular-list {
  margin: 10px 0 0 25px;
}

.popular-list li {
  text-transform: lowercase;
  margin-top: 3px;
  margin-bottom: 3px;
}

.popular-list li a {
  text-transform: capitalize;
}

.footerContact-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.footer-middleBorder {
  height: .5px;
  width: 80%;
  margin: 2rem auto;
}

.footer-middleBorder hr {
  height: 1px;
  background-color: #7f6a59;
  border: 0;
  margin: 0 auto;
}

.footer-trademark {
  max-width: 1500px;
  justify-content: space-between;
  margin: auto;
  display: flex;
}

@media screen and (max-width: 1700px) {
  html {
    font-size: 18px;
  }

  .slogan-description {
    font-size: 1.75rem;
  }

  .slogan-link {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .container-logo {
    width: 25%;
  }

  header {
    position: relative;
  }

  .nav-links {
    flex-direction: column;
    flex-basis: 100%;
    display: none;
  }

  .burger-menu {
    display: initial;
    cursor: pointer;
    font-size: 2rem;
  }

  nav {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .8rem;
  }

  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    padding: 2rem;
  }

  .consultation-form {
    height: 50%;
  }

  footer {
    font-size: 15px;
  }
}

@media screen and (max-width: 720px) {
  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}

@media screen and (max-width: 530px) {
  .nav-links {
    display: none;
  }

  .container-logo {
    width: 45%;
  }

  .main-btn {
    white-space: normal;
  }

  .portfolio-btn {
    width: 60%;
  }

  .service-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }

  .slogan-heading {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }

  .porftolio-grid-container {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }

  .slogan-container {
    padding: 4rem 1rem;
  }

  .company-wrapper {
    flex-direction: column;
  }

  .company-wrapper > * {
    width: 100%;
  }

  footer section {
    padding: 0;
  }

  .footer-middleBorder {
    width: 100%;
  }

  .footer-trademark {
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    gap: 2rem;
    padding: 1.5rem;
  }

  .mailing-wrapper {
    flex-direction: column;
  }

  .mailing-wrapper :first-child {
    border-top: 0;
  }

  .mailing-wrapper > * {
    width: 100%;
    border-top: 1px solid #7f6a59;
    border-left: 0;
  }
}

/*# sourceMappingURL=index.84df350b.css.map */
